import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'
import styled from 'styled-components'

const StyContainer = styled.div`
  padding: 0 1rem;
  height: 50px;
  font-size: 1.0rem;
  font-weight: 600;
  letter-spacing: 0.15rem;
  
  background-color: rgb(67, 74, 90);
  box-shadow: 0 0.2rem 0.3rem 0 rgba(0, 0, 0, 0.8);
  
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyMiPnetLogo = styled.img`
  width: 50px;
  box-shadow: none;
`
const StyMiPnetText = styled.div`
  margin-left: 5px;

  @media only screen and (max-width: 600px) {
    margin-left: unset;
    visibility: hidden;
    width: 0;
  }
`

const StyLink = styled.a`
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
`

type SiteTitleQueryResult = {
  site: { siteMetadata: { title: string, siteUrl: string } }
}

const Header: FC<{}> = () => {
  const siteTitleQueryResult: SiteTitleQueryResult = useStaticQuery(graphql`
    query SiteTitleQuery {
      site { siteMetadata { title siteUrl }}
    }
  `)

  return (
    <StyContainer>
      <StyLink href="https://mipnet.de">
        <StyMiPnetLogo src="../mipnet-logo.png" alt="Logo" />
        <StyMiPnetText>MiPnet</StyMiPnetText>
      </StyLink>
      <StyLink href="../">{siteTitleQueryResult.site.siteMetadata.title}</StyLink>
      <StyLink href="../about">
        About
      </StyLink>
    </StyContainer>
  )
}

export default Header
