import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import styled, { createGlobalStyle } from 'styled-components'
import Footer from './footer'
import Header from './header'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: rgb(30,30,30);
    color: rgb(255,255,255);
    margin: 0;

    @font-face {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: normal;
      src: local('Nunito-Light'), url(../../fonts/Nunito/Nunito-Light.woff) format('woff');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
    }
    
    @font-face {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: bold;
        src: local('Nunito-Regular'), url(../../fonts/Nunito/Nunito-Regular.woff) format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
    }
    
    @font-face {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 900;
        src: local('Nunito-Bold'), url(../../fonts/Nunito/Nunito-Bold.woff) format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
    }

    font-family: 'Nunito', sans-serif;
    font-size: 1.0em;
  }

  img {
    box-shadow: 0 0.2rem 0.3rem 0 rgba(0, 0, 0, 0.8);
  }

  a {
    color: rgb(255, 255, 97);
    text-decoration: none;
    :hover {
      color: rgb(255, 255, 255);
    }
}
`
const StyContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const StyMain = styled.div`
  height: 100%;
  flex-grow: 1;
  padding: 4rem 4rem;
  
  @media only screen and (max-width: 1200px) {
    padding: 3rem;
  }

  @media only screen and (max-width: 600px) {
    padding: 1rem;
  }
`

type SiteMetadataQueryResult = {
  site: { siteMetadata: { title: string, description: string, siteUrl: string}}
}

const MainPage: FC<{children: React.ReactNode}> = ({ children }) => {
  const siteMetadataQueryResult: SiteMetadataQueryResult = useStaticQuery(graphql`
  query SiteMetadata {
      site { siteMetadata { title description siteUrl } }
    }
  `)

  return (
    <>
      <GlobalStyle />
      { /* @ts-ignore */ }
      <Helmet>
        <meta charSet="utf-8" />
        <title>{siteMetadataQueryResult.site.siteMetadata.title}</title>
        <link rel="canonical" href={siteMetadataQueryResult.site.siteMetadata.siteUrl} />
        <meta name="Description" content={siteMetadataQueryResult.site.siteMetadata.description} />
        <meta name="keywords" content="Lego, Blog" />
      </Helmet>
      <StyContainer>
        <Header />
        <StyMain>
          { children }
        </StyMain>
        <Footer />
      </StyContainer>
    </>
  )
}

export default MainPage
